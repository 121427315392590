import axios from "axios";
import { authHeader } from "../../helpers/authservice/auth-header";

/*
 * Enpoints para la parte de autenticacion
 */

//Url de api
// console.log("ENTORNO");
// console.log(process.env.VUE_APP_ENV === "DEV");
let urlApi =
  process.env.VUE_APP_ENV === "DEV"
    ? process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL_PRODUCTION;


//Funcion Leer 
const manifiestoCambiarEstado = async (body) => {
  try {
    const result = await axios.post(`${urlApi}/apideliveryns/cloud/v1/TraficNS/Manifiestos/cambiarEstado`,
    body, { headers: authHeader()}
    );
    return { result };
  } catch (error) {
    if (error.response) {
      // Limpiar localstoreage al detectar token vencido
      return { state: false, message: error.response.data.message }; // => the response payload
    }
  }
};

export { manifiestoCambiarEstado };
