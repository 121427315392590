<template>
  <div>
    <flat-pickr
      v-model="dateRange"
      :config="config"
      @on-change="emitDateRange"
      class="form-control"
    />
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Spanish } from "flatpickr/dist/l10n/es.js"; // Importa localización en español

export default {
  name: "DateRangePicker",
  components: {
    flatPickr,
  },
  props: {
    defaultDateRange: {
      type: Array,
      required: false,
      default: () => [null, null], // valores por defecto si no se pasa ninguno
    },
  },
  data() {
    return {
      dateRange: this.defaultDateRange,
      config: {
        mode: "range",
        dateFormat: "Y-m-d",
        locale: {
          ...Spanish, // Usamos la localización en español como base
          rangeSeparator: " hasta ", // Cambiamos "to" por "hasta"
          firstDayOfWeek: 1, // Opcional: Define el primer día de la semana como lunes
        },
      },
    };
  },
  methods: {
    emitDateRange(selectedDates) {
      this.$emit("update-date-range", selectedDates);
    },
  },
};
</script>

<style scoped>
/* Estilos opcionales */
</style>
