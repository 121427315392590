<script>
import tableBasic from "@/components/tables/tableBasic.vue";
import dateRangePicker from "@/components/others/dateRangePicker.vue";

import "flatpickr/dist/flatpickr.css";

import myLoader from "@/components/myLoader.vue";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";

//   import Create from './create.vue';
//   import Edit   from './edit.vue';
//   import CreateConcept from './concepts/create.vue';
//   import EditConcept from './concepts/edit.vue';

import {
  leerManfiestosSinAnticipo,
  leerAnticiposSinManfiesto,
  vincularAnticipo,
  vincularAnticipo52,
} from "@/api/utilidades/manifiestosSinAnticipo";

import { manifiestoCambiarEstado } from "@/api/operaciones/manifiestos";

import Swal from "sweetalert2";

export default {
  page: {
    title: appConfig.description,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    tableBasic,
    myLoader,
    dateRangePicker,
  },
  data() {
    return {
      title: "Vinculación Especial de Anticipos",
      items: [
        {
          text: "UTILIDADES",
          href: "/Mod_NS_Travel/ns_FollowUpTravel",
        },
        {
          text: "Vinculación Especial de Anticipos",
          active: true,
        },
      ],

      isMain: true,
      isLoader: true,
      defaultDateRange: this.getDefaultDateRange(), // Asignamos el rango de fechas predeterminado
      selectedDateRange: this.getDefaultDateRange(),
      columns: [
        {
          title: "Tipo",
        },
        {
          title: "Manifiesto",
        },
        {
          title: "Fecha",
        },
        {
          title: "Agencia",
        },
        {
          title: "CreadoPor",
        },
        {
          title: "Placa",
        },
        {
          title: "Propiedad",
        },
        {
          title: "Cedula",
        },
        {
          title: "Conductor",
        },
        {
          title: "Carga",
        },
        {
          title: "Descarga",
        },
      ],
      columns2: [
        {
          title: "Tipo",
        },
        {
          title: "Manifiesto",
        },
        {
          title: "Fecha",
        },
        {
          title: "Agencia",
        },
        {
          title: "CreadoPor",
        },
        {
          title: "Placa",
        },
        {
          title: "Propiedad",
        },
        {
          title: "Cedula",
        },
        {
          title: "Conductor",
        },
        {
          title: "Carga",
        },
        {
          title: "Descarga",
        },
      ],
      columnsAnticipos: [
        {
          title: "Descripción",
        },
        {
          title: "Prefijo",
        },
        {
          title: "Documento",
        },
        {
          title: "Fecha",
        },
        {
          title: "Valor",
        },
        {
          title: "Estado",
        },
        {
          title: "Tercero",
        },
        {
          title: "Anulado",
        },
        {
          title: "Fecha Anulación",
        },
        {
          title: "Razón Anulación",
        },
      ],
      currentPage: 1,
      currentPageAnticipos: 1,
      pageSize: 10,
      pageSizeAnticipos: 10,
      qLink: 5,
      dataManifiestos: null,
      dataAnticipos: null,
      linkManifiestos: null,
      linkAnticipos: null,
      currentManifiesto: null,
      currentCedula: null,
      currentPartner: 1,
      currentPartnerName: null,
      currentConcept: null,
      switchDeleteConcept: 0, //0 - Borra lista 1 - Borra Concepto
    };
  },

  watch: {
    // contactsList() {
    //   this.setPages();
    // },
  },
  // created() {
  //   this.setPages();
  // },

  methods: {
    getDefaultDateRange() {
      const today = new Date(); // Fecha actual
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1); // Primer día del mes actual
      return [
        firstDayOfMonth.toISOString().split("T")[0], // Primer día del mes (YYYY-MM-DD)
        today.toISOString().split("T")[0], // Fecha actual (YYYY-MM-DD)
      ];
    },
    handleDateRangeUpdate(newDateRange) {
      this.selectedDateRange = newDateRange;
    },
    keyPartner(key) {
      // this.currentPartner = key;
      this.isMain = false;
      var result = this.dataManifiestos.filter((obj) => obj.ManifiestoNum == key);
      this.dataManifiestoSelect = result;
      this.currentManifiesto = key;
      (this.currentCedula = result[0].Cedula),
        // carga anticipos-
        this.get_anticipos(this.currentCedula);
    },
    keyAsociarAnticipoManfiesto(key) {
      var data = this.dataAnticipos.filter((obj) => obj.DocNumber == key);
      var DocNumber = data[0].DocNumber;
      var Propio = data[0].Propio;
      var RowId = data[0].RowId;
      if (data[0].Estado != "Contabilizado" || data[0].Anulado == "SI") {
        Swal.fire(
          "Proceso no permitido!",
          "El anticipo no está contabilizado o anulado!",
          "error"
        );
        return;
      }
      console.log("224-", data);
      console.log("225-", JSON.parse(localStorage.getItem("dataUser")).username);
      Swal.fire({
        title: "Vincular Anticipo a Manifiesto",
        text:
          "Vincula el anticipo :" +
          DocNumber +
          " al manifiesto : " +
          this.currentManifiesto,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, proceda!",
      }).then((result) => {
        if (result.value) {
          if (Propio == 1) {
            this.MethodelinkAvance(
              this.currentManifiesto,
              RowId,
              JSON.parse(localStorage.getItem("dataUser")).username
            );
          } else {
            this.MethodelinkAvance52(
              this.currentManifiesto,
              RowId,
              JSON.parse(localStorage.getItem("dataUser")).username
            );
          }
          Swal.fire("Cambiado!", "El manifiesto fue actualizado.", "success");
        }
      });
    },
    deletePartner(key) {
      this.keyPartner(key);
      Swal.fire({
        title: "Desea Borrar la lista:" + this.currentPartnerName + "?",
        showDenyButton: true,
        confirmButtonText: "Borrar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.switchDeleteConcept = 0;
          this.delete_typifications();
          Swal.fire("Borrada!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Lista no borrada", "", "info");
        }
      });
    },
    updateManifiesto(key) {
      Swal.fire({
        title: "Marca el Manifiesto:" + key + " como liquidado?",
        text: "Esto no puede cambiarse!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, cámbielo!",
      }).then((result) => {
        if (result.value) {
          this.changeStatus_manifiestos(key);
          Swal.fire("Cambiado!", "El manifiesto fue actualizado.", "success");
        }
      });
    },
    updateConcept(key) {
      this.currentConcept = key;
      document.getElementById("btn-edit-concept").click();
    },
    deleteConcept(key) {
      this.currentConcept = key;
      var result = this.dataManifiestosConcept.filter((obj) => obj.RowId == key);
      Swal.fire({
        title: "Desea Borrar el concepto:" + result[0].Name + "?",
        showDenyButton: true,
        confirmButtonText: "Borrar",
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.switchDeleteConcept = 1;
          this.delete_typifications();
          Swal.fire("Concepto borrado!", "", "success");
        } else if (result.isDenied) {
          Swal.fire("Concepto no borrado", "", "info");
        }
      });
    },
    pageChange(page) {
      this.currentPage = page;
      this.get_manifiestos();
    },
    pageChangeAnticipo(page) {
      this.currentPageAnticipos = page;
      this.get_anticipos(this.currentCedula);
    },

    async MethodelinkAvance(ManifiestoNum, RowReference, User) {
      this.isLoader = true;

      const result = await vincularAnticipo({
        NewManifiesto: ManifiestoNum,
        AdvRowRef: RowReference,
        User: User,
      });

      console.log("331-", result);

      this.isLoader = false;
    },

    async MethodelinkAvance52(ManifiestoNum, RowReference, User) {
      this.isLoader = true;

      const result = await vincularAnticipo52({
        NewManifiesto: ManifiestoNum,
        AdvRowRef: RowReference,
        User: User,
      });

      console.log("344-", result);

      this.isLoader = false;
    },

    async changeStatus_manifiestos(ManifiestoNum) {
      this.isLoader = true;

      const result = await manifiestoCambiarEstado({
        ManifiestoNum: ManifiestoNum,
        MftStatus: 4,
      });
      console.log("changeStatus_manifiestos", result);
      this.get_manifiestos();

      //this.isLoader = false;
    },

    async get_manifiestos() {
      this.isLoader = true;

      let filterSearch = [];

      var filter = document.getElementById("filtroManfiesto").value;
      if (filter.length > 2) {
        filterSearch.push({
          campo: "ManifiestoNum",
          operador: "like",
          valor: "%" + filter + "%",
        });
      }
      filter = document.getElementById("filtroCedula").value;
      if (filter.length > 2) {
        filterSearch.push({
          campo: "Driver",
          operador: "like",
          valor: "%" + filter + "%",
        });
      }
      filter = document.getElementById("filtroPlaca").value;
      if (filter.length > 2) {
        filterSearch.push({
          campo: "Vehicle",
          operador: "like",
          valor: "%" + filter + "%",
        });
      }

      filterSearch = filterSearch.length === 0 ? "XXNNXX" : filterSearch;
      const result = await leerManfiestosSinAnticipo({
        page: this.currentPage,
        pageSize: this.pageSize,
        fecha_inicio: this.selectedDateRange[0],
        fecha_fin: this.selectedDateRange[1],
        filter: filterSearch,
      });
      console.log("193-", result);
      this.dataManifiestos = [];
      this.dataManifiestos = result.data.data.data;
      this.linkManifiestos = result.data.data.links;

      this.currentPartner = 1;
      this.currentPartnerName = "";

      this.isLoader = false;
    },
    async get_anticipos(cedula) {
      this.isLoader = true;

      let filterSearch = [];

      // var filter = document.getElementById("filtroManfiesto").value;
      // if (filter.length > 2) {
      //   filterSearch.push({
      //     campo: "ManifiestoNum",
      //     operador: "like",
      //     valor: "%" + filter + "%",
      //   });
      // }
      // filter = document.getElementById("filtroCedula").value;
      // if (filter.length > 2) {
      //   filterSearch.push({
      //     campo: "Driver",
      //     operador: "like",
      //     valor: "%" + filter + "%",
      //   });
      // }
      // filter = document.getElementById("filtroPlaca").value;
      // if (filter.length > 2) {
      //   filterSearch.push({
      //     campo: "Vehicle",
      //     operador: "like",
      //     valor: "%" + filter + "%",
      //   });
      // }

      filterSearch = filterSearch.length === 0 ? "XXNNXX" : filterSearch;
      const resultAnt = await leerAnticiposSinManfiesto({
        page: this.currentPageAnticipos,
        pageSize: this.pageSize,
        cedula: cedula,
        filter: filterSearch,
      });
      console.log("319-", resultAnt.data);
      this.dataAnticipos = [];
      this.dataAnticipos = resultAnt.data.data.data;
      this.linkAnticipos = resultAnt.data.data.links;

      this.isLoader = false;
    },
  },
  mounted() {
    // toma datos de tipificaciones
    this.get_manifiestos();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-12">
        <div class="card" id="PartnetList">
          <div class="card-header">
            <div v-show="isMain" class="row g-3">
              <div class="col-md-2">
                <label for="Período" class="form-label">Período</label>
                <dateRangePicker
                  :defaultDateRange="defaultDateRange"
                  @update-date-range="handleDateRangeUpdate"
                />
              </div>
              <div class="col-md-2">
                <label for="filtroManifiesto" class="form-label">Manifiesto</label>
                <div class="search-box">
                  <input
                    type="text"
                    id="filtroManfiesto"
                    class="form-control search"
                    placeholder="Buscar manifiesto..."
                  />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <div class="col-md-2">
                <label for="filtroPlaca" class="form-label">Placa</label>
                <div class="search-box">
                  <input
                    type="text"
                    id="filtroPlaca"
                    class="form-control search"
                    placeholder="Buscar placa..."
                  />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>
              <div class="col-md-2">
                <label for="filtroCedula" class="form-label">Cédula</label>
                <div class="search-box">
                  <input
                    type="text"
                    id="filtroCedula"
                    class="form-control search text-success"
                    placeholder="Buscar cédula..."
                  />
                  <i class="ri-search-line search-icon"></i>
                </div>
              </div>

              <div class="col-md-1">
                <label for="filtrar" class="form-label">Filtrar</label>
                <div class="search-box">
                  <a
                    @click="this.get_manifiestos"
                    href="javascript:void(0);"
                    class="avatar-title bg-soft-info text-success fs-2 rounded"
                  >
                    <i class="ri-search-line"></i>
                  </a>
                </div>
              </div>
              <div class="col-md-auto ms-auto">
                <div class="d-flex align-items-center gap-2">
                  <!-- filtro -->
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div v-show="isMain">
              <tableBasic
                :columns="columns"
                :data="dataManifiestos"
                :currentPage="currentPage"
                :pageSize="pageSize"
                :links="linkManifiestos"
                buttonKey="1"
                buttonEdit="1"
                buttonDelete="0"
                itemKey="1"
                @pageChange="pageChange"
                @updateKey="updateManifiesto"
                @key="keyPartner"
                @deleteKey="deletePartner"
              />
            </div>
            <div v-show="!isMain">
              <div class="row g-3 mb-4">
                <div class="col d-flex align-items-center justify-content-between">
                  <h2 class="text-primary mb-0">MANIFIESTO</h2>
                  <b-button
                    pill
                    variant="success"
                    class="btn-label waves-effect waves-light"
                    @click="isMain = true"
                    ><i
                      class="ri-arrow-go-back-line label-icon align-middle rounded-pill fs-16 me-2"
                    ></i>
                    Regresar</b-button
                  >
                </div>
              </div>
              <tableBasic
                :columns="columns"
                :data="dataManifiestoSelect"
                :currentPage="currentPage"
                :pageSize="pageSize"
                buttonKey="."
                buttonEdit="1"
                buttonDelete="0"
                itemKey="1"
                @updateKey="updateManifiesto"
              />
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div v-show="!isMain" class="row">
      <div class="col-xxl-12">
        <div class="card" id="AnticipoList">
          <div class="card-header">
            <div class="row g-3">
              <h2 class="text-primary">ANTICIPOS</h2>
            </div>
          </div>
          <div class="card-body">
            <div v-show="!isMain">
              <tableBasic
                :columns="columnsAnticipos"
                :data="dataAnticipos"
                :currentPage="currentPageAnticipos"
                :pageSize="pageSizeAnticipos"
                :links="linkAnticipos"
                buttonKey="1"
                buttonEdit="0"
                buttonDelete="0"
                itemKey="2"
                @key="keyAsociarAnticipoManfiesto"
                @pageChange="pageChangeAnticipo"
              />
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <!-- Myloader -->
    <div v-if="isLoader" style="position: absolute; top: 35%; left: 20%; z-index: 9999">
      <myLoader />
    </div>
  </Layout>
</template>
